import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 d-none d-md-block"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is NFT Token Development?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">NFT Token Development refers to the process of creating digital assets (non-fungible tokens) on blockchain platforms, allowing unique ownership and verification of digital or physical items, such as art, collectibles, and virtual goods.</div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                What is the cost of creating an NFT?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The cost of creating an NFT varies based on the platform and blockchain used. Expenses typically include minting fees (gas fees), platform charges, and any associated design or development costs.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How to make money by creating NFT?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Creators can make money by selling NFTs directly to buyers, earning royalties from secondary market sales, or creating and selling exclusive digital content. Businesses may also monetize NFTs through brand partnerships or unique offerings.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the legal considerations when creating and selling NFTs?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Legal considerations include copyright ownership, intellectual property rights, and ensuring compliance with local laws regarding digital assets and cryptocurrency transactions. Creators should also be aware of potential tax obligations related to NFT sales.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the key benefits of creating NFT for businesses?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">NFTs allow businesses to create unique digital assets. They can help build brand loyalty and create new ways to earn money through digital collectibles, exclusive content, and tokenized products. This also boosts customer engagement.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                What are the technical specifications for NFT Development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">NFT Development requires knowledge of blockchain technology, smart contracts, and web3 development tools. Creators also need experience with wallet integrations, NFT Marketplaces, and minting processes.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection