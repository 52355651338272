import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




class UsecaseOf extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2">Our NFT Token Applications for <span className="bluecolor">various Industries</span></h3>
                <p className="pharagraph head text-center">As a leading NFT Development company, we offer transformative solutions across various industries, providing innovative ways to digitize and secure assets.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
            <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/use-case.webp"
            alt="Use Case of NFT Token Development image1"
            width={500}
          />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Art -</b> Digital art can be tokenized into NFTs, ensuring secure ownership and traceability on the blockchain. Creators can trade their work as valuable digital assets with indisputable ownership.
              </p>
              <p className="pharagraph" ><b>Collectibles -</b> NFTs enable the secure buying, selling, and trading of rare items, preserving their authenticity and uniqueness. They solve issues of verification and replication in traditional collectibles.  
              </p>
              <p className="pharagraph" ><b>Copyright protection -</b>  NFTs offer a secure way to protect copyrights globally, ensuring that original ownership is traceable and verifiable through blockchain transactions.
              </p>
              <p className="pharagraph" ><b>Licenses & certificates -</b> NFTs provide a secure method to verify licenses and certificates, embedding authentication data in smart contracts for tamper-proof validation.
              </p>
              <p className="pharagraph" ><b>Sports -</b> NFTs reduce counterfeit sales of tickets and merchandise by digitizing them into tokens, ensuring secure, transparent transactions.
              </p>
              <p className="pharagraph mb-0" ><b>Real estate -</b> Using smart contracts to enable seamless property ownership transfers, NFTs simplify and secure real estate transactions.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default UsecaseOf