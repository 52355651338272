import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


const DevApproach = () => {


  return (
    <div className='pt-100'>
    <section className="devapproach">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="bluecolor">Coinsclone’s Process</span> Of NFT Token Development
            </h3>
            <p className="pharagraph head">Creativity bleeds from a proper and aligned roadmap. We are experts in framing the NFT Development process without any hassles.</p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/consulting.webp"
            alt="Consulting image1"
            width={350}
          />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h4 >Initial consultation</h4>
            <p  className="pharagraph">Our development team starts with validating your ideologies for the non-fungible token creation. Then, we guide you in framing the process, cost estimation, and other specifications for creating an NFT token.
            </p>
            <br />
            <h4 >Roadmap Framing </h4>
            <p  className="pharagraph">Our technical team then strategizes the necessary resources, requirements, and tools for NFT Development without any hassles. Our blueprint will comprise the creation to the execution stage for startups.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h4 >Smart Contract Development</h4>
            <p  className="pharagraph">We ensure to offer advanced-level feature options in your token’s smart contract. We integrate seamless smart contract codes to make sure the non-fungible token’s quality is high and secure crypto transactions.
            </p>
            <br />
            <h4 >NFT MetaData Creation</h4>
            <p  className="pharagraph">After the creation of smart contracts, we help you to fill the metadata details like description, title, and other attributes for the tokens. These attributes are linked to the non-fungible token’s URI. 
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/smart-contract.webp"
            alt="Smart Contract image1"
            width={320}
          />
           </div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/testing.webp"
            alt="Testing image1"
            width={315}
          />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h4 >Testing</h4>
            <p  className="pharagraph">Once the smart contract for non-fungible tokens is developed, our development team will help you sort out the errors, bugs, and technical issues. We assure to deliver a non-fungible token with the highest quality and scalability.
            </p>
            <br />
            <h4 >NFT Token Launch</h4>
            <p  className="pharagraph">After all these processes, you can launch or list your NFT token for your business purpose. Our development team is always ready to offer prolonged support and maintenance for the NFT token.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default DevApproach