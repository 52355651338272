import React from 'react'


const Exponential = () => {


  return (
    <section className="industries pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Exponential Benefits </span> Of Our NFT Tokens
            </h2>
            <p className="pharagraph head text-center">We strive to create NFT Tokens that shape your future while offering immense profitability. By leveraging a cutting-edge tech stack, we deliver high-quality NFTs that stand out in the digital space.
            </p>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <h3>Unique</h3>
                <p className='pharagraph'>Our NFTs are distinct and possess exclusive characteristics that set them apart. Each token is entirely original, ensuring it cannot be copied or replicated in any way, safeguarding authenticity.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Ownership</h3>
                <p className='pharagraph'>NFT ownership is stored securely on distributed ledger technology. As a token holder, you gain full rights and access to the original NFT, ensuring complete control of your asset.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Indivisible</h3>
                <p className='pharagraph'>Unlike fungible tokens, NFTs cannot be split or divided into smaller units. Transactions involve transferring complete ownership and maintaining the token’s integrity and uniqueness.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Multi-chain support </h3>
                <p className='pharagraph'>We design NFTs to be interoperable across multiple blockchain networks. Our team ensures each token is tailored to meet your business needs for flexibility and scalability.</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Exponential