import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">NFT Token Development Company</h1>
            <p className="sub-heading">Coinsclone, a leading NFT Development company, offers premium non-fungible token creation services designed for startups and businesses.</p>
            <p className="sub-small">Our experienced NFT developers help you create secure NFTs on popular blockchains, integrating the latest features to meet your unique business needs.</p>
          </div>
          <div className="quick text-center d-none d-md-block">
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/nfttoken/nft-token-development.png"
            alt="NFT Token Development Company image1"
            width={810}
          />
          <p className='sub-small mt-3 mt-lg-4'>As a trusted partner, we streamline the development process, empowering your business for faster growth in the digital world.</p>
          </div>
          <div className="text-center mt-4">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection