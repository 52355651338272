import React from "react"

class DevServices extends React.Component {

  render() {
    return (
      
      <section className="icon whitelabel-innove nftoken pt-100 secureof">
        <div className="container">
          <h2 className="heading-h2 text-center">Our NFT Token <span className='bluecolor'>Development Services</span></h2>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Marketplace Development
                </h3>
                <p className="pharagraph">Empower your business with our custom NFT Marketplace solutions. We create secure, user-friendly platforms for buying, selling, and trading NFTs. Our services offer high scalability, multi-chain compatibility, and features like bidding systems, wallet integration, and multi-currency support for an exceptional marketplace experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Smart Contract Development
                </h3>
                <p className="pharagraph">Our team specializes in developing and deploying secure smart contracts tailored for NFTs. We integrate advanced features to handle NFT transactions, ownership transfers, and royalties with utmost precision. Leverage our expertise to create tamper-proof, automated, and efficient NFT ecosystems backed by blockchain technology.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href="https://www.coinsclone.com/nft-staking-platform-development/">NFT Staking Platform Development</a>
                </h3>
                <p className="pharagraph mb-lg-0">Boost the utility of your NFTs with our advanced NFT staking platform development services. We enable NFT holders to stake their assets and earn rewards, enhancing user engagement and ecosystem participation. With robust smart contracts, customizable reward mechanisms, and secure protocols, we help you stand out in the NFT space.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href="https://www.coinsclone.com/nft-minting-platform-development/">NFT Minting Platform Development</a>
                </h3>
                <p className="pharagraph mb-lg-0">Launch your own NFT minting platform with our cutting-edge development solutions. We create intuitive and scalable platforms where users can easily mint their NFTs with a few clicks. Our services include customization options, blockchain compatibility, and seamless integration with NFT Marketplaces to ensure a smooth user experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                  <a href="https://www.coinsclone.com/nft-lending-platform-development/">NFT Lending Platform Development</a>
                </h3>
                <p className="pharagraph mb-lg-0">Our NFT lending platform development revolutionizes the NFT ecosystem. We enable NFT owners to leverage their digital assets as collateral to secure loans. Our platforms are equipped with smart contract automation, secure protocols, and multi-chain integration to ensure transparency and trustworthiness in every transaction.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Auction Platform Development
                </h3>
                <p className="pharagraph mb-lg-0">Set new standards in the NFT auction space with our custom auction platform development services. We create dynamic auction systems that support various bidding types. With secure payment gateways, live tracking, and anti-sniping mechanisms, our solutions deliver a seamless and fair auction experience.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default DevServices
